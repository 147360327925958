// FYI : We had to overwrite the script.js from before but we couldn't access the source.
// As a result, all the js is not include here, only the new layer of scripts.

$('.owl-postuler').owlCarousel({
    loop:true,
    autoplay: true,
    margin:0,
    nav:true,
    items: 1,
    navText: ['<svg version="1.1" id="left" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF;} .st1{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;} </style> <polygon class="st0" points="6.9,15.2 18.8,22.1 18.8,8.4 "/> <circle class="st1" cx="15" cy="15" r="14.3"/> </svg>','<svg version="1.1" id="right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF;} .st1{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;} </style> <polygon class="st0" points="22.6,15.2 10.8,8.4 10.8,22.1 "/> <circle class="st1" cx="15" cy="15" r="14.3"/> </svg>']
});
$('.owl-carousel-chief').owlCarousel({
    loop:true,
    autoplay: true,
    margin:0,
    nav:true,
    items: 1,
    navText: ['<svg version="1.1" id="left" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF;} .st1{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;} </style> <polygon class="st0" points="6.9,15.2 18.8,22.1 18.8,8.4 "/> <circle class="st1" cx="15" cy="15" r="14.3"/> </svg>','<svg version="1.1" id="right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF;} .st1{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;} </style> <polygon class="st0" points="22.6,15.2 10.8,8.4 10.8,22.1 "/> <circle class="st1" cx="15" cy="15" r="14.3"/> </svg>']
});

$('.owl-carousel').owlCarousel({
    loop:true,
    margin:0,
    nav:true,
    items: 1,
    navText: ['<svg version="1.1" id="left" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF;} .st1{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;} </style> <polygon class="st0" points="6.9,15.2 18.8,22.1 18.8,8.4 "/> <circle class="st1" cx="15" cy="15" r="14.3"/> </svg>','<svg version="1.1" id="right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve"> <style type="text/css"> .st0{fill:#FFFFFF;} .st1{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;} </style> <polygon class="st0" points="22.6,15.2 10.8,8.4 10.8,22.1 "/> <circle class="st1" cx="15" cy="15" r="14.3"/> </svg>']
});






// SINGLE SOLUTIONS

$('#labels.desktop').find('.labelsContent').click(function(){

	// Open and Close LabelsContent
	$('#labels.desktop').find('.labelsText').removeClass('open');
	$('#labels.desktop').find('.labelsContent').removeClass('open');
	$('#labels.desktop').find('.labelsText').css('height', 1);
	$('#labels.desktop').find(this).find('.labelsText').addClass('open');
	$('#labels.desktop').find(this).addClass('open');
	var height = $(this).find('.labelsText').find('p').outerHeight(true);
	$('#labels.desktop').find(this).find('.labelsText').css('height', height + 30);

	// Display the slider corresponding
	var labelKey = $(this).data('key');
	$('#labels.desktop').find('.labelCarouselContainer').removeClass('active');
	$('#labels.desktop').find('.labelCarouselContainer').eq(labelKey).addClass('active');

});


$('#labels.mobile').find('.labelsContent').click(function(){
	var height = $(this).find('.labelsText').outerHeight(true) + 420;
	$('.labelsContent').removeClass('active');
	$(this).addClass('active');
	$('.labelCarouselContainer').css('height', 0);
	$(this).find('.labelCarouselContainer').css('height', height);
	$('.labelCarouselContainer').removeClass('active');
	$(this).find('.labelCarouselContainer').addClass('active');
});

$('.singleSolutions').find('#labels.desktop').find('.labelsContent').first().trigger( "click" );


// SINGLE PRODUCTS

$('.singleProduct').find('.labelsTitleContainer').click(function(){

	$('.labelsTitleContainer').removeClass('active');
	$(this).addClass('active');

	// Display the slider corresponding
	var labelKey = $(this).data('key');
	$('.labelscontentContainer').removeClass('active');
	$('.labelscontentContainer').eq(labelKey).addClass('active');

});

$('.singleProduct').find('.labelsTitleContainer').first().trigger( "click" );


// SECTION MENU DESKTOP NOS SOLUTIONS / NOS PRODUITS

function sectorMaxHeight(){

	var titleHeightMax = 0;

	$('#sectors').find('.title').each(function(){

		var titleHeight = $(this).outerHeight(true);
		titleHeightMax = Math.max(titleHeightMax, titleHeight);

	});

	$('#sectors').find('.title').css('min-height', titleHeightMax);

}

sectorMaxHeight();
$(window).resize(sectorMaxHeight);


$('#sectors').find('.item').find('.onHover').css('height', 0);

    var titleHeightMax = 0;

    $('#sectors').find('.title').each(function(){

    	var titleHeight = $(this).outerHeight(true);
    	titleHeightMax = Math.max(titleHeightMax, titleHeight);

    });

    $('#sectors').find('.item').find('.title').css('min-height', titleHeightMax);

    $('#sectors').find('.item').find('.itemContainer').css('min-height', titleHeightMax);

$('#sectors').find('.item').hover(function(){


	var onHoverHeight = $(this).find('.excerpt').outerHeight(true) + $(this).find('.btnSectors').outerHeight(true)
	$(this).find('.onHover').css('height', onHoverHeight);

	var itemHeight = $(this).outerHeight();
	$(this).find('.itemContainer').css('min-height', itemHeight);

	$(this).find('.title').css('min-height', 0);




}, function() {

    $(this).find('.onHover').css('height', 0);

    var titleHeightMax = 0;

    $('#sectors').find('.title').each(function(){

    	var titleHeight = $(this).outerHeight(true);
    	titleHeightMax = Math.max(titleHeightMax, titleHeight);

    });

    $(this).find('.title').css('min-height', titleHeightMax);

    $(this).find('.itemContainer').css('min-height', titleHeightMax);

});


$('#sectors2').find('.item').click(function(){

	$('#sectors2').find('.item').removeClass('open');
	$('.itemContent').css('height', 0);
	$(this).addClass('open');

	var height = $(this).find('.itemContentContainer').outerHeight(true) + 100;
	$(this).find('.itemContent').css('height', height);
});



$('#sectors2').find('.item').first().trigger( "click" );


$('.conditionnal_input_color').on('change', function() {
    if ($(this).attr('id') == 'request_nb_colors_other') {
        $('.conditionnal_color').addClass('show_other_color');
    } else {
        $('.conditionnal_color').removeClass('show_other_color');        
    }
})
